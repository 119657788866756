import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import ContentPage from '../sections/advanced/facecontent.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Hydra from "../sections/advanced/hydra.js";
import Lux from "../sections/advanced/lux.js";
import Needling from "../sections/advanced/needle";
import Tailor from "../sections/advanced/tailor";
import Vamp from "../sections/advanced/vamp";
import Mes from "../sections/advanced/mes";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/facial.jpg";
import PopupForm from "../components/PopupForm/PopupForm"

const AdvancedFacialPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
  <>
 <Head title="Advanced Facial Treatments in London UK" image="/images/dermamina-home.jpg"  description="Premium facials tailored to your skin needs, soothing facial, instant radiance and glow." keywords="Advanced face treatments, hydrafacial, Dermalux LED, Mesotherapy, Facial Glow, make face glow, smooth face treatment, PRP facial london"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Advanced Facial Treatments London',
item:'https://www.dermamina.com/advanced-facial-treatments-london',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Advanced Facial </span> <span style="color: #00aec7;">Treatments London</span>'
    secondText="Get ready to glow"
    videoAlt="Advanced Facials"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />
     
   
      <ContentPage />
      <Tailor setIsModalOpen={setIsModalOpen}/>
     
      <Vamp setIsModalOpen={setIsModalOpen}/>
      <Hydra
        setIsModalOpen={setIsModalOpen}
      />
      <Needling setIsModalOpen={setIsModalOpen}/>
      <Mes setIsModalOpen={setIsModalOpen}/>
      <Lux setIsModalOpen={setIsModalOpen}/>
      <Clinic />
      <Saveface />
      <Trend />
 
     </PageWrapper>
   
  </>
)}
export default AdvancedFacialPage
